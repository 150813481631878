import { Action } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  blockfaces,
  cameras,
  CityCode,
  geoProcessor,
  meters,
  parkingEvents,
  reports,
  revenueData,
  sensors,
  signs,
  spots,
  studyAreas,
  transactions,
  zones,
} from '../../../../services';
import { RootState } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { curbSpacesGeoActions } from './curb-spaces-geo-slice';
import { curbSpacesLayerActions } from './curb-spaces-layer-slice';
import { selectedCurbSpacesActions } from './selected-curb-spaces-slice';
import { createInactiveCameraState, ISelectedCurbSpace, SensorOccupancyStatus } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';

const fetchCurbSpacesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(curbSpacesGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadSpots(action.payload.box, action.payload.box.zoom, action.payload.policies, action.payload.statuses, action.payload.sensors)
        .pipe(
          map((x) => curbSpacesGeoActions.fetchSuccess(x)),
          catchError((err) => of(curbSpacesGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const fetchCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(curbSpacesLayerActions.fetchCount.match),
    concatMap(() =>
      zip(from(spots.getCount()), from(sensors.getCount())).pipe(
        mergeMap(([curbSpacesCount, sensorsCount]) => of(curbSpacesLayerActions.fetchCountSuccess({ curbSpacesCount, sensorsCount }))),
        catchError((err) => of(curbSpacesLayerActions.fetchCountFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() =>
      of(
        curbSpacesLayerActions.fetchPolicyTypes(),
        curbSpacesLayerActions.fetchStatuses(),
        curbSpacesLayerActions.fetchCount(),
        curbSpacesLayerActions.fetchStatusCount(),
        selectedCurbSpacesActions.collapsePopups(),
      ),
    ),
  );

const fetchPolicyTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(curbSpacesLayerActions.fetchPolicyTypes.match),
    concatMap(() =>
      from(spots.getSpotPolicyTypes()).pipe(
        map((types) => types.filter((x) => x !== 0)),

        map((x) => curbSpacesLayerActions.fetchPolicyTypesSuccess(x)),
        catchError((err) => of(curbSpacesLayerActions.fetchPolicyTypesFailed(err.message))),
      ),
    ),
  );

const loadCurbSpace = (id: number, state: RootState): Observable<ISelectedCurbSpace> => {
  const existing = state.selectedCurbSpaces.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  const statisticsPeriod = HeatmapPeriod.LastDay.calcPeriod();

  return zip(
    from(spots.get(id)),
    from(cameras.getCameraBySpotsIds([id])),
    from(signs.getSpotSignsId([id])),
    from(spots.getSpotState(id)),
    from(meters.getSpotMeterNames(id)),
    from(studyAreas.getSpotStudyAreaNames([id])),
  ).pipe(
    mergeMap(([spot, cameraIdWithSpots, signIds, spotStates, meterNames, studyAreas]) =>
      zip(
        of({ spot, spotStates, meterNames, studyAreas }),
        signIds.length > 0 ? from(signs.getSignState(signIds[0])) : of(null),
        cameraIdWithSpots.length > 0
          ? zip(from(cameras.get(cameraIdWithSpots[0].CameraId)), from(cameras.getState(cameraIdWithSpots[0].CameraId))).pipe(
              map((res) => ({
                camera: res[0],
                cameraState: res.length > 1 && res[1] ? res[1] : createInactiveCameraState(res[0].Id),
              })),
            )
          : of({ camera: null, cameraState: null }),
        spot.ZoneId ? from(zones.get(spot.ZoneId)) : of(null),
        cameraIdWithSpots.length > 0 ? from(parkingEvents.getByCamera(cameraIdWithSpots[0].CameraId)) : of([]),
        spot.ParkingSensorId ? from(sensors.getTelemetry(spot.ParkingSensorId)) : of(null),
        spot.ParkingSensorId
          ? from(sensors.getSpotStatistics(spot.DataSourceEntityId, statisticsPeriod[0], statisticsPeriod[1]))
          : of(null),
        spot.BlockfaceId ? from(blockfaces.get(spot.BlockfaceId)) : of(null),
      ).pipe(
        map(([spot, signState, camera, zone, cameraEvents, sensorTelemetry, statistics, blockface]) => {
          return {
            ...spot.spot,
            state: spot.spotStates.length ? spot.spotStates[0] : null,
            metersNames: spot.meterNames,
            signState,
            camera: camera.camera,
            cameraState: camera.cameraState,
            zone,
            cameraEvents,
            sensorTelemetry:
              state.cities.selectedCity?.Code === CityCode.Minneapolis
                ? {
                    Id: 0,
                    Name: spot.spot.Name,
                    Status: SensorOccupancyStatus.Unknown,
                    ChargeLevel: null,
                    Temperature: null,
                    UpdatedAt: new Date(),
                  }
                : sensorTelemetry,
            statistics,
            blockface,
            studyAreas: spot.studyAreas,
          };
        }),
      ),
    ),
  );
};

const findCurbSpaceFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  return state.curbSpacesGeo.data.features.find((x) => x.properties?.id === id) || null;
};

const curbSpaceSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedCurbSpacesActions.load.match),
    concatMap((action) =>
      loadCurbSpace(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              curbSpacesLayerActions.setEnabled(true),
              selectedCurbSpacesActions.loadSuccess({ curbSpace: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findCurbSpaceFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: geoUtils.toGeometryPointOrPolygon(x.Positions),
              properties: {},
            };

            const center = geoUtils.findCenter(feature.geometry).coordinates;
            return of(
              curbSpacesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedCurbSpacesActions.loadSuccess({ curbSpace: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedCurbSpacesActions.loadFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedCurbSpacesActions.closePopups()),
  );

const fetchStatisticsEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchStatistics.match),
    concatMap((action) => {
      const dataSourceEntityId = state$.value.selectedCurbSpaces.selected.find((x) => x.id === action.payload.id)?.entity
        ?.DataSourceEntityId;
      if (!dataSourceEntityId) return of();
      return from(sensors.getSpotStatistics(dataSourceEntityId, action.payload.period[0], action.payload.period[1])).pipe(
        map((x) => selectedCurbSpacesActions.fetchStatisticsSuccess({ id: action.payload.id, statistics: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchStatisticsFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchOccupancyReport.match),
    concatMap((action) => {
      return from(reports.getSpotOccupancyReport(action.payload.curbSpaceId, action.payload.occupancySource)).pipe(
        map((x) => selectedCurbSpacesActions.fetchOccupancyReportSuccess({ id: action.payload.curbSpaceId, report: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchOccupancyReportFailed(err.message))),
      );
    }),
  );
};

const fetchEventsEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchEvents.match),
    concatMap((action) => {
      return from(parkingEvents.getBySpotId(action.payload)).pipe(
        map((x) => selectedCurbSpacesActions.fetchEventsSuccess({ id: action.payload, events: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchEventsFailed(err.message))),
      );
    }),
  );
};

const fetchRegulationEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchRegulation.match),
    concatMap((action) => {
      return from(spots.getSpotRegulation(action.payload)).pipe(
        map((x) => selectedCurbSpacesActions.fetchRegulationSuccess({ id: action.payload, regulation: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchRegulationFailed(err.message))),
      );
    }),
  );
};

const fetchStatusCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(curbSpacesLayerActions.fetchStatusCount.match),
    concatMap(() =>
      from(spots.getStatusCount()).pipe(
        map((x) => curbSpacesLayerActions.fetchStatusCountSuccess(x)),
        catchError((err) => of(curbSpacesLayerActions.fetchStatusCountFailed(err.message))),
      ),
    ),
  );

const fetchTransactionsEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchTransactions.match),
    concatMap((action) => {
      return from(transactions.getTopSpotTransactions(action.payload)).pipe(
        map((x) => selectedCurbSpacesActions.fetchTransactionsSuccess({ id: action.payload, transactions: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchTransactionsFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueBySpot(action.payload)).pipe(
        map((x) => selectedCurbSpacesActions.fetchRevenueSuccess({ id: action.payload, revenue: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

const fetchKpiReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchKpiReport.match),
    concatMap((action) => {
      return from(reports.getSpotKpiReport(action.payload)).pipe(
        map((x) => selectedCurbSpacesActions.fetchKpiReportSuccess({ id: action.payload, report: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchKpiReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancySourceReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedCurbSpacesActions.fetchOccupancySourceReport.match),
    concatMap((action) => {
      return from(reports.getSpotOccupancySourceReport(action.payload.id, action.payload.occupancySource, action.payload.period)).pipe(
        map((x) => selectedCurbSpacesActions.fetchOccupancySourceReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedCurbSpacesActions.fetchOccupancySourceReportFailed({ id: action.payload.id, error: err.message }))),
      );
    }),
  );
};

export const curbSpacesEpic = combineEpics(
  fetchCurbSpacesEpic,
  citySelectedEpic,
  fetchPolicyTypesEpic,
  curbSpaceSelectedEpic,
  closePopupsEpic,
  fetchCount,
  fetchStatisticsEpic,
  fetchOccupancyReportEpic,
  fetchEventsEpic,
  fetchRegulationEpic,
  fetchStatusCount,
  fetchTransactionsEpic,
  fetchRevenueEpic,
  fetchKpiReportEpic,
  fetchOccupancySourceReportEpic,
);
